<template>
  <other-navbar :currentView="'home'"></other-navbar>
  <div>
    <header>
      <img src="../../assets/homeMediano.jpeg" class="top-img" />
      <div class="presentation">
        <img
          src="../../assets/logo-a-ugalde-y-asociados.png"
          :height="logoHeight"
        />
        <h1>¿Quiénes somos?</h1>
        <p>{{ presentacion }}</p>
        <ul>
          <li v-for="tarea in tareas" :key="tarea">{{ tarea }}</li>
        </ul>
      </div>
    </header>
    <div class="container">
      <div class="card">
        <img
          src="../../assets/goal.png"
          height="60"
          width="60"
          style="margin: 10px 40%"
        />
        <h2>Misión</h2>
        <p>{{ mision }}</p>
      </div>
      <div class="card">
        <img
          src="../../assets/vision1.png"
          height="60"
          width="60"
          style="margin: 10px 40%"
        />
        <h2>Visión</h2>
        <p>{{ vision }}</p>
      </div>
      <div class="card">
        <img
          src="../../assets/valores.png"
          height="60"
          width="60"
          style="margin: 10px 40%"
        />
        <h2>Valores</h2>
        <ul>
          <li v-for="valor in valores" :key="valor">{{ valor }}</li>
        </ul>
      </div>
    </div>
    <p class="credits">
      Imágen del ecnabezado: Foto de
      <a
        href="https://unsplash.com/@uxindo?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        class="credit-link"
      >
        UX Indonesia
      </a>
      en
      <a
        href="https://unsplash.com/es/fotos/8mikJ83LmSQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        class="credit-link"
      >
        Unsplash
      </a>
      <br />
      Íconos:
      <a
        target="_blank"
        href="https://icons8.com/icon/20884/goal"
        class="credit-link"
      >
        Goal </a
      >,
      <a
        target="_blank"
        href="https://icons8.com/icon/e6D8D0kCogYG/opera-glasses"
        class="credit-link"
      >
        Opera Glasses </a
      >,
      <a
        target="_blank"
        href="https://icons8.com/icon/37291/morale"
        class="credit-link"
      >
        Morale
      </a>
      por
      <a target="_blank" href="https://icons8.com" class="credit-link">
        Icons8
      </a>
    </p>
  </div>
  <the-footer></the-footer>
</template>

<script>
export default {
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      presentacion:
        "Ugalde y Asociados es una empresa consultora mexicana en temas de educación superior integrada por un grupo de profesionales expertos en los diferentes campos de conocimiento con el propósito de:",
      tareas: [
        "Realizar el diseño curricular, instruccional, gráfico y web para planes y programas de estudios del tipo superior en todos los niveles, grados y modalidades",
        "Asesorar a las instituciones educativas en la integración de procesos documentados relacionados con la operación y la calidad de los servicios para la creación de valor",
        "Ofrecer consultoria para la organización y desarrollo de la planeación estratégica de las instituciones educativas",
      ],
      mision:
        "Apoyar a las instituciones de educación superior en la integración de su oferta educativa mediante la elaboración de planes y programas de calidad, innovadores y de vanguardia que logren el Reconocimiento de Validez Oficial de Estudios (RVOE).",
      vision:
        "Ser un despacho de consultoria educativa que logra la satisfacción de sus clientes mediante la entrega de soluciones de alta calidad",
      valores: ["Confiabilidad", "Responsabilidad", "Garantía"],
    };
  },
  computed: {
    logoHeight() {
      if (this.width < 835) {
        return "60";
      } else {
        return "170";
      }
    },
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
header {
  position: relative;
  height: fit-content;
  margin-bottom: 10px;
}
.presentation {
  /*width: 60rem;*/
  width: 75%;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 2rem;
  background-color: white;
  position: absolute;
  top: 12%;
  left: 10%;
  opacity: 95%;
}
.container {
  display: flex;
  width: 80%;
  margin: 3% 10%;
  gap: 15px;
}
.card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin: 2rem auto;
  background-color: #a0bfdf;
  width: 300px;
}

li {
  text-align: left;
}

.top-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

@media (max-width: 1120px) {
  header {
    position: static;
    width: 100%;
  }
  .presentation {
    position: static;
    padding: 15px;
    width: auto;
    margin: 7px;
    left: 0;
  }
  .container {
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 15px;
    padding: 0;
    width: 95%;
    gap: 5px;
  }

  .card {
    margin: 5px;
    width: 100%;
  }
}

.credits {
  font-size: 10px;
  text-align: left;
  margin: 0 0 5px 2px;
}

.credit-link {
  color: #2d353d;
  margin: 0;
}
</style>
