<template>
  <the-navbar :currentView="'clients'"></the-navbar>
  <p style="font-size: larger; text-align: left; margin: 20px 10px 20px 15px">
    Algunos de nuestros clientes son:
  </p>
  <the-carousel :switchValue="autoPlaySwitch"></the-carousel>
  <div class="autoplay-switch">
    <p>Autoplay on</p>
    <label class="switch">
      <input type="checkbox" v-model="autoPlaySwitch" />
      <span class="slider round"></span>
    </label>
    <!-- <input type="checkbox" v-model="autoPlaySwitch" /> -->
  </div>
  <the-footer></the-footer>
</template>

<script>
import TheCarousel from "../TheCarousel.vue";
export default {
  components: { TheCarousel },
  data() {
    return {
      autoPlaySwitch: true,
    };
  },
};
</script>

<style>
.autoplay-switch {
  display: flex;
  font-size: 16px;
  padding: 0 8px;
  margin: 0px 0px 15px 0px;
  /* color: #5a6775; */
  color: #6b7a8a;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  transform: translate(4px, 19px);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6aa0cc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6aa0cc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
