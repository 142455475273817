<template>
  <the-navbar :currentView="'services'"></the-navbar>
  <div>
    <header>
      <img src="../../../assets/asesoriaMediano.jpeg" class="bg-img" />
      <div class="header">
        <!-- <h1>Asesoría para planeación estratégica</h1> -->
        <h1>Capacitación para el diseño curricular e instruccional</h1>
        <p>
          Considerando la posibilidad de que las instituciones educativas deseen
          capacitar a su personal en materia de diseño curricular y diseño
          instruccional para programas en modalidad mixta o no escolarizada,
          ofrecemos cursos de entrenamiento en estos campos pedagógicos.
        </p>
        <div class="container">
          <div v-for="servicio in servicios" :key="servicio" class="card">
            {{ servicio }}
          </div>
        </div>
      </div>
    </header>
    <p class="credits">
      Imágen empleada: foto de
      <a
        href="https://unsplash.com/@johnschno?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        class="credit-link"
      >
        John Schnobrich
      </a>
      en
      <a
        href="https://unsplash.com/es/fotos/FlPc9_VocJ4?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        class="credit-link"
      >
        Unsplash
      </a>
    </p>
  </div>
  <the-footer></the-footer>
</template>

<script>
export default {
  data() {
    return {
      servicios: [
        //"Consultoría para el desarrollo de la planeación estratégica de instituciones educativas",
      ],
    };
  },
};
</script>

<style scoped>
header {
  position: relative;
}
.bg-img {
  object-fit: cover;
  /* height: 100%; */
  width: 85%;
  margin-left: 15%;
  clip-path: polygon(
    52% 14%,
    100% 2%,
    100% 43%,
    100% 78%,
    68% 100%,
    39% 82%,
    6% 78%,
    0% 43%,
    12% 8%
  );
}
.header {
  border-radius: 12px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  padding: 2rem;
  margin: 2rem auto;
  max-width: 65%;
  background-color: #e7f1f8;
  position: absolute;
  top: 27px;
  left: 60px;
  opacity: 95%;
  border-radius: 0 0 25% 0;
}

.container {
  display: flex;
  width: 80%;
  gap: 20px;
  margin: auto;
  height: 200px;
}
.card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin: 2rem auto;
  background-color: #a0bfdf;
  width: 450px;
}

.credits {
  font-size: 10px;
  text-align: left;
  margin: 0px;
}

.credit-link {
  color: #2d353d;
  margin: 0;
}

@media (max-width: 768px) {
  header {
    position: static;
  }
  .header {
    max-width: 95%;
    margin: 10px;
    padding: 10px;
    position: static;
    opacity: 100%;
    border-radius: 0;
  }
  .container {
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto;
  }
  .card {
    margin: 10px;
    width: 100%;
  }
}
</style>
