<template>
  <div class="pop-up" @click="$emit('close')">
    <dialog open>
      <slot></slot>
    </dialog>
  </div>
</template>

<script>
export default {
  emits: { close },
};
</script>

<style scoped>
.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
dialog {
  margin: 0;
  position: fixed;
  top: 23vh;
  left: 30%;
  width: 40%;
  background-color: #a0bfdf;
  padding: 2rem;
  border: 0px;
  text-align: center;
  color: #2d353d;
  font-size: larger;
}

@media (max-width: 768px) {
  dialog {
    width: 80%;
    left: 5%;
  }
}
</style>
