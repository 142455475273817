<template>
  <!-- versión desktop -->
  <nav v-if="!isMovile">
    <ul class="ul-desktop">
      <li class="us-desktop li-desktop">
        <h3 style="font-size: 40px; margin-left: 30px; margin-top: 13px">
          Ugalde y Asociados
        </h3>
      </li>
      <div style="float: right; display: flex; width= 100%">
        <router-link to="/">
          <li class="menubutton-desktop li-desktop" :class="setHomeActive">
            Inicio
          </li>
        </router-link>

        <li
          class="menubutton-desktop-services li-desktop"
          :class="setServicesActive"
        >
          <a href="#" @click="showServices = true" class="service-butn">
            Servicios
          </a>

          <div class="dropdown-content" :class="mostrarServicios">
            <li class="service-option" @click="ocultarServicios">
              <router-link to="/disenocurricular">
                <!-- Diseno Curricular e Institucional -->
                Diseño de planes y programas de estudios
              </router-link>
            </li>
            <li class="service-option" @click="ocultarServicios">
              <router-link to="/asesoriadiseno">
                <!-- Asesoría para diseño de procesos educativos -->
                Diseño de planes y programas de ciencias de la salud
              </router-link>
            </li>
            <li class="service-option" @click="ocultarServicios">
              <router-link to="/asesoriaplaneacion">
                <!-- Asesoría para planeación estratégica -->
                Capacitación para el diseño curricular e instruccional
              </router-link>
            </li>
          </div>
        </li>

        <router-link to="/clientes">
          <li class="menubutton-desktop li-desktop" :class="setClientsActive">
            Nuestros clientes
          </li>
        </router-link>

        <router-link to="/contacto">
          <li class="menubutton-desktop li-desktop" :class="setContactActive">
            Contacto
          </li>
        </router-link>
      </div>
    </ul>
  </nav>

  <!-- Versión móvil-->
  <nav v-else>
    <ul class="ul-movil">
      <li class="us-movil">
        <h3
          style="
            font-size: 26px;
            font-weight: 900;
            margin-top: 15px;
            margin-bottom: 10px;
          "
        >
          Ugalde y Asociados
        </h3>
      </li>
      <li class="menubutton-movil" @click="toggleSidebar">
        <h3>Menu</h3>
      </li>
    </ul>
    <ul v-if="isSidebarOpen" class="menuoptions-movil">
      <li class="menu-li-desktop" :class="setHomeActive" @click="toggleSidebar">
        <router-link to="/"> Inicio </router-link>
      </li>

      <li
        class="menu-li-desktop"
        :class="setServicesActive"
        @click="toggleMovileServices"
      >
        <a>Servicios</a>
      </li>

      <li
        v-if="servicesClicked"
        class="services-option-movil"
        @click="toggleSidebar"
      >
        <router-link to="/disenocurricular">
          <!-- Diseno Curricular e Institucional -->
          Diseño de planes y programas de estudios
        </router-link>
      </li>
      <li
        v-if="servicesClicked"
        class="services-option-movil"
        @click="toggleSidebar"
      >
        <router-link to="/asesoriadiseno">
          <!-- Asesoría para diseño de procesos educativos -->
          Diseño de planes y programas de ciencias de la salud
        </router-link>
      </li>
      <li
        v-if="servicesClicked"
        class="services-option-movil"
        @click="toggleSidebar"
      >
        <router-link to="/asesoriaplaneacion">
          <!-- Asesoría para planeación estratégica -->
          Capacitación para el diseño curricular e instruccional
        </router-link>
      </li>

      <li
        class="menu-li-desktop"
        :class="setClientsActive"
        @click="toggleSidebar"
      >
        <router-link to="/clientes">Nuestros clientes</router-link>
      </li>

      <li
        class="menu-li-desktop"
        :class="setContactActive"
        @click="toggleSidebar"
      >
        <router-link to="/contacto"> Contacto </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["currentView"],
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      servicesClicked: false,
      showServices: false,
      showMovileServices: false,
      isSidebarOpen: false,
    };
  },
  computed: {
    isMovile() {
      if (this.width < 835) {
        return true;
      } else {
        return false;
      }
    },
    mostrarServicios() {
      return this.showServices === true ? "show-dropdown" : "hide-dropdown";
    },
    setHomeActive() {
      if (this.width < 835) {
        return this.currentView === "home" && !this.showMovileServices
          ? "selected"
          : null;
      } else {
        return this.currentView === "home" ? "selected-desktop" : null;
      }
    },
    setServicesActive() {
      if (this.width < 835) {
        return this.currentView === "services" || this.servicesClicked
          ? "selected"
          : null;
      } else {
        return this.currentView === "services" ? "selected-desktop" : null;
      }
    },
    setClientsActive() {
      if (this.width < 835) {
        return this.currentView === "clients" && !this.showMovileServices
          ? "selected"
          : null;
      } else {
        return this.currentView === "clients" ? "selected-desktop" : null;
      }
    },
    setContactActive() {
      if (this.width < 835) {
        return this.currentView === "contact" && !this.showMovileServices
          ? "selected"
          : null;
      } else {
        return this.currentView === "contact" ? "selected-desktop" : null;
      }
    },
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    toggleServices() {
      this.servicesClicked = !this.servicesClicked;
    },
    ocultarServicios() {
      this.showServices = false;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      this.servicesClicked = false;
      this.showMovileServices = false;
    },
    toggleMovileServices() {
      this.servicesClicked = true;
      this.showMovileServices = true;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
a {
  display: block;
  text-decoration: none;
  color: white;
  background-color: none;
}

a:hover {
  text-decoration: none;
  color: white;
  background-color: none;
}

nav {
  background-color: #0e2747;
  max-height: min-content;
}

.ul-desktop {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
}

.li-desktop {
  float: left;
  display: block;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 20px 0px;
  min-width: max-content;
}
.us-desktop {
  /*   min-width: 40%;
    font-size: 34px;
    font-weight: bold; */
  position: absolute;
  top: -16px;
  left: 5px;
}
.menubutton-desktop {
  width: 10rem;
  padding: 29px 7px;
}

.menubutton-desktop:hover {
  /*background-color: #4994d1;*/
  background-color: #669fce;
}
.service-butn {
  padding: 0px;
  margin: 18px 0px;
  width: 10rem;
  background-color: transparent;
  color: white;
}

.selected-desktop {
  background-color: #3f88c5;
}
.selected-desktop:active,
.selected-desktop:hover {
  background-color: #6aa0cc;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #0e2747;
  color: white;
  min-width: 160px;
  z-index: 1;
}

.show-dropdown {
  display: block;
}

.hide-dropdown {
  display: none;
}

.service-option {
  padding: 3px 10px;
}
.service-option:hover {
  color: white;
  background-color: #6aa0cc;
}

.menubutton-desktop-services {
  width: 15%;
  padding: 9px 0;
}
.menubutton-desktop-services:hover {
  /*background-color: #4994d1;*/
  background-color: #669fce;
}

/* Movil Styles */
.ul-movil {
  list-style-type: none;
  overflow: hidden;
  background-color: #0e2747;
  /*   background-color: white; */
  max-height: min-content;
  display: flex;
  margin: 0;
  padding: 0;
}

.us-movil {
  float: left;
  display: block;
  color: white;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: -3px;
  width: 70%;
  padding: 5px 5px;
}
.menubutton-movil {
  width: 30%;
  float: left;
  display: block;
  color: white;
  text-align: center;
  background-color: #6aa0cc;
}

.menuoptions-movil {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-height: min-content;
}

.menu-li-desktop {
  color: white;
  padding: 5px;
  border: 1px solid white;
}

.services-option-movil {
  padding: 5px;
  border: 1px solid white;
  background-color: #6aa0cc;
}

.selected {
  background-color: #3f88c5;
}
</style>
