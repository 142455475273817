<template>
  <footer>
    <ul>
      <li>Ugalde y Asociados <br /></li>
      <li>
        Director: <br />
        Juan Gerardo Ugalde Rosas
      </li>
      <li>Teléfono: <br />55 38 88 81 40</li>
      <li>Correo: <br />iq.jgur@gmail.com</li>
      <li class="direction">
        Ubicación: <br />Vía Lopez Portillo No. 53-A Interior 13, Coacalco
        Estado de México
      </li>
    </ul>
  </footer>
</template>

<style scoped>
footer {
  font-size: 14px;
  background-color: #0e2747;
  border-top-left-radius: 15%;
  border-top-right-radius: 15%;
}
ul {
  list-style-type: none;
  margin: 0 15px 0 35px;
  padding: 10px;
  overflow: hidden;
}

li {
  float: left;
  color: #8593a6;
  text-align: center;
  margin-top: 20px;
  margin-left: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 15%;
  border-top: 2px solid #6f8096;
  border-bottom: 2px solid #6f8096;
}

.direction {
  width: 23%;
}

@media (max-width: 768px) {
  footer {
    border-radius: 0;
  }
  li {
    float: none;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 0px;
  }
  .direction {
    width: 100%;
  }
}
</style>
