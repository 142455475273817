import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";

//Components
import TheNavbar from "./components/TheNavbar.vue";
import OtherNavbar from "./components/OtherNavbar.vue";
import TheFooter from "./components/TheFooter.vue";
import TheHome from "./components/NavbarOptions/TheHome.vue";
import TheContact from "./components/NavbarOptions/TheContact.vue";
import OurClients from "./components/NavbarOptions/OurClients.vue";
import DisenoCurricular from "./components/NavbarOptions/Services/DisenoCurricular.vue";
import AsesoriaDiseno from "./components/NavbarOptions/Services/AsesoriaDiseno.vue";
import AsesoriaPlaneacion from "./components/NavbarOptions/Services/AsesoriaPlaneacion.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { name: "inicio", path: "/", component: TheHome },
    { name: "contacto", path: "/contacto", component: TheContact },
    { name: "clientes", path: "/clientes", component: OurClients },
    {
      name: "disenocurricular",
      path: "/disenocurricular",
      component: DisenoCurricular,
    },
    {
      name: "asesoriadiseno",
      path: "/asesoriadiseno",
      component: AsesoriaDiseno,
    },
    {
      name: "asesoriaplaneacion",
      path: "/asesoriaplaneacion",
      component: AsesoriaPlaneacion,
    },
  ],
});

/* let currentPath;

router.beforeEach(function (to, from, next) {
  console.log(to.name, from);
  currentPath = to.name;
  console.log("Current Path  " + currentPath);
  next();

const app = createApp(App);
app.inject('thePath', currenPath)
}) */

const app = createApp(App);

app.component("the-navbar", TheNavbar);
app.component("the-footer", TheFooter);
app.component("other-navbar", OtherNavbar);
app.use(router);
app.use(VueAxios, axios);
app.mount("#app");
