<template>
  <Carousel
    :items-to-show="numberOfSlides"
    :wrap-around="true"
    :autoplay="autoPlayTime"
  >
    <Slide v-for="slide in slides" :key="slide.id">
      <div class="carousel__item">
        <p>{{ slide.nombre }}</p>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>

  <!--   <Carousel
    v-else
    :items-to-show="numberOfSlides"
    :wrap-around="true"
    :autoplay="autoPlayTime"
  >
    <Slide v-for="slide in slides" :key="slide.id">
      <div class="carousel__item">
        <img :src="getImgUrl(slide.ruta)" :alt="slide.nombre" width="290" />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel> -->
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  components: { Carousel, Slide, Navigation },
  props: ["switchValue"],
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      slides: [
        {
          id: "1",
          nombre: "Centro de Estudios Superiores del Oriente de Michoacán",
          ruta: "cesom-logo",
        },
        {
          id: "2",
          nombre:
            "Instituto Mexicano de Investigación y Desarrollo en Ciencias Policiales",
          ruta: "imidecip-logo",
        },
        {
          id: "3",
          nombre: "Centro Universitario República de México",
          ruta: "republica-logo",
        },
        {
          id: "4",
          nombre: "Universidad Contemporánea de las Américas",
          ruta: "unicla-logo",
        },
        {
          id: "5",
          nombre: "Universidad Interamericana",
          ruta: "universidad-interamericana-logo",
        },
        {
          id: "6",
          nombre: "Universidad Mundo Abierto",
          ruta: "universidad-mundo-abierto-logo",
        },
        {
          id: "7",
          nombre: "Universidad Sor Juana",
          ruta: "universidad-sorjuana-logo",
        },
        {
          id: "8",
          nombre: "Universidad Cuauhtémoc",
          ruta: "universidad-cuauhtemoc-logo",
        },
        {
          id: "9",
          nombre: "Universidad Regional de los Altos",
          ruta: "ura-logo",
        },
        {
          id: "10",
          nombre: "Centro Universitario de Guanajuato",
          ruta: "ceug-logo",
        },
        {
          id: "11",
          nombre: "Centro de Formación ALSER",
          ruta: "alser-logo",
        },
      ],
    };
  },
  computed: {
    numberOfSlides() {
      if (this.width < 835) {
        return "1";
      } else {
        return "2.5";
      }
    },
    autoPlayTime() {
      if (this.switchValue) {
        return "3000";
      } else {
        return "0";
      }
    },
    slideWidth() {
      if (this.width < 835) {
        return "450";
      } else {
        return "130";
      }
    },
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    /*     getImgUrl(cliente) {
      var images = require.context("../assets/logos-b", false, /\.png$/);
      return images("./" + cliente + ".png");
    }, */
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.carousel__item {
  min-height: 350px;
  width: 95%;
  background-color: #a0bfdf;
  color: #2d353d;
  font-size: 40px;
  border-radius: 8px;
  justify-content: center;
  align-content: center;
  margin: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-items: center;
}

.carousel__slide {
  margin: 0px 3px 0px 3px;
}

.carousel__slide--prev,
.carousel__slide--next {
  opacity: 70%;
  transform: scale(0.87);
}

@media (max-width: 768px) {
  .carousel__item {
    width: 80%;
    margin: auto;
    padding: 10px;
  }
  .carousel__slide {
    margin: 0px 0px 20px 0px;
  }
}
</style>
