<template>
  <div>
    <the-navbar :currentView="'contact'"></the-navbar>
    <div class="container">
      <div class="left-side">
        <div class="contact-text">
          <h1>¡Contáctanos!</h1>
          <p>
            Para mayor información sobre nuestros servicios no dude en
            contactarnos, estamos comprometidos a asistirle.
          </p>
        </div>
        <div>
          <img
            src="../../assets/contactoPequeno2.jpeg"
            alt="Manos sosteniendo un celular"
          />
        </div>
      </div>
      <form @submit.prevent="validateSubmision">
        <label>Nombre</label>
        <input type="text" name="nombre" v-model="submitedName" />
        <teleport to="body">
          <form-alert v-if="!validName" @close="resetValues('name')">
            <h3>Nombre no válido</h3>
            <p>Por favor, ingrese un nombre válido</p>
            <button @click="resetValues('name')" class="pop-up-butn">Ok</button>
          </form-alert>
        </teleport>

        <label>Correo</label>
        <input type="text" name="mail" v-model="submitedEmail" />
        <teleport to="body">
          <form-alert v-if="!validEmail" @close="resetValues('email')">
            <h3>Correo no válido</h3>
            <p>Por favor, ingrese un correo válido</p>
            <button @click="resetValues('email')" class="pop-up-butn">
              Ok
            </button>
          </form-alert>
        </teleport>

        <label>Asunto</label> <br />
        <textarea
          rows="7"
          class="asunto"
          name="asunto"
          v-model="submitedMessage"
        >
        </textarea>
        <teleport to="body">
          <form-alert v-if="!validMessage" @close="resetValues('message')">
            <h3>Mensaje no válido</h3>
            <p>Por favor, ingrese un mensaje válido</p>
            <button @click="resetValues('message')" class="pop-up-butn">
              Ok
            </button>
          </form-alert>
        </teleport>

        <button type="submit">Enviar</button>

        <teleport to="body">
          <form-alert
            v-if="submitionSuccesfull"
            @close="resetValues('success')"
          >
            <h3>¡Éxito!</h3>
            <p>Se ha enviado tu formulario</p>
            <button @click="resetValues('success')" class="pop-up-butn">
              Ok
            </button>
          </form-alert>
        </teleport>

        <teleport to="body">
          <form-alert v-if="errorA || errorB" @close="resetValues('error')">
            <h3>¡Lo sentimos!></h3>
            <p>Ha habido un error, vuelva a intentarlo</p>
            <button @click="resetValues('error')" class="pop-up-butn">
              Ok
            </button>
          </form-alert>
        </teleport>
      </form>
    </div>
    <p class="credits">
      Imágen empleada: foto de
      <a
        href="https://unsplash.com/@gibonskc?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        class="credit-link"
      >
        Michal Biernat
      </a>
      en
      <a
        href="https://unsplash.com/es/fotos/h0xEUQXzU38?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        class="credit-link"
      >
        Unsplash
      </a>
    </p>
  </div>
  <the-footer></the-footer>
</template>

<script>
import FormAlert from "../FormAlert.vue";

export default {
  components: { FormAlert },
  data() {
    return {
      submitedName: "",
      validName: true,
      submitedEmail: "",
      validEmail: true,
      submitedMessage: "",
      validMessage: true,
      submitionSuccesfull: null,
      errorA: null,
      errorB: null,
    };
  },
  methods: {
    resetValues(value) {
      if (value === "name") {
        this.validName = true;
      } else if (value === "email") {
        this.validEmail = true;
      } else if (value === "message") {
        this.validMessage = true;
      } else if (value === "success") {
        this.submitionSuccesfull = null;
      } else if (value === "error") {
        this.errorA = null;
        this.errorB = null;
      }
    },
    validateSubmision() {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.submitedName === "") {
        this.validName = false;
        return;
      } else {
        this.validName = true;
      }
      if (this.submitedEmail === "" || !this.submitedEmail.match(validRegex)) {
        this.validEmail = false;
        return;
      } else {
        this.validEmail = true;
      }
      if (this.submitedMessage === "") {
        this.validMessage = false;
        return;
      } else {
        this.validMessage = true;
      }
      console.log("info al right");
      this.axios
        .post("https://usebasin.com/f/702de2a8a677", {
          nombre: this.submitedName,
          mail: this.submitedEmail,
          asunto: this.submitedMessage,
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            this.submitionSuccesfull = true;
            this.submitedName = "";
            this.submitedEmail = "";
            this.submitedMessage = "";
          } else {
            this.errorA = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorB = error.message;
        });
    },
  },
};
</script>

<style scoped>
.container {
  height: auto;
  margin: 30px 10%;
  border-radius: 12px;
  display: flex;
  padding: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.26);
}

.left-side {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}

.contact-text {
  padding: 20px;
  background-color: white;
}

img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}

button {
  background-color: #0e2747;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
}

button:hover {
  background-color: #3f88c5;
}
form {
  padding: 2rem;
  background-color: #a0bfdf;
  width: 45%;
}

input {
  display: block;
  width: 100%;
  font: inherit;
  margin-top: 0.5rem;
}

textarea {
  font: inherit;
  width: 100%;
}

.pop-up-butn {
  width: 3rem;
}
.credits {
  font-size: 10px;
  text-align: left;
  margin: 0px;
}

.credit-link {
  color: #2d353d;
  margin: 0;
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    height: auto;
    margin: 20px 10px;
  }
  .left-side {
    width: 100%;
  }
  form {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
